<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <b-img
            style="width: 200px;"
            :src="require('@/assets/images/logo/logo.png')"
          />
        </b-link>

        <b-card-title class="mb-1">
          Bem vindo! 👋
        </b-card-title>
        <b-card-text class="mb-2">
          Faça login na sua conta e acesse o portal
        </b-card-text>

        <validation-observer
          ref="validacao"
          #default="{invalid}"
        >
          <b-form
            class="auth-login-form mt-2"
            @submit.prevent
          >
            <b-form-group
              label-for="email"
              label="E-mail"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="form.login"
                  name="login-email"
                  :state="errors.length > 0 ? false:null"
                  placeholder="contato@empresa.com.br"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <div class="d-flex justify-content-between">
                <label for="password">Senha</label>
              </div>
              <senha-input
                :id="'senhaInput'"
                v-model="form.senha"
                :required="true"
                :validacao-nome="'Password'"
              />
              <div class="d-flex justify-content-between">
                <b-link :to="{name:'auth-forgot-password-v1'}">
                  <small
                    style="display: flex; margin-top: 0.5rem;"
                    @click="esqueceuSenha"
                  >Esqueceu a senha?</small>
                </b-link>
              </div>
            </b-form-group>
            <b-button
              variant="primary"
              type="submit"
              block
              :disabled="invalid"
              @click="autenticar"
            >
              Entrar
            </b-button>
          </b-form>
        </validation-observer>
      </b-card>
    </div>
  </div>
</template>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'
// eslint-disable-next-line import/no-cycle
import {
  updateAbility, clearLoggedIn,
} from '@/auth/utils'
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt'
import eventHub from '@/app/shared/hubs/eventHub'
import Vue from 'vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    SenhaInput: () => import('@pilar/components/senha-input/SenhaInput.vue'),
  },
  data() {
    return {
      form: {
        login: '',
        senha: '',
      },
      esqueci: null,
      loginError: '',
      required,
      email,
    }
  },
  created() {
    clearLoggedIn()
    // para conexão com a hub (para evitar conexão com usuário antigo ao trocar de usuário)
    const connection = eventHub()
    connection.stop()
    this.$swal({
      title: '<div style="color:darkgreen;">Plataforma homologada pela Receita Federal</div>',
      html: `
      '<div style="color:green; font-size: 18px;">Bem-vindo!
        <br>
        Informamos que nossa plataforma está <b>homologada no e-CAC</b> e é 
        especializada em <b> automatizar</b> o acesso a esse ambiente. Isso significa 
        que você pode gerenciar suas obrigações fiscais de forma mais eficiente e segura, com 
        integração direta ao sistema da Receita Federal!
        <br><br><br>
        Atenciosamente Equipe Monitor Contábil
        </div>'
      `,
      icon: 'warning',
      iconColor: '#28a745',
      showConfirmButton: true,
      width: '40rem',
      customClass: {
        popup: 'swal-m',
        confirmButton: 'swal-btn-green',
      },
      didOpen: () => {
        const popup = document.querySelector('.swal2-popup')
        if (popup) {
          popup.style.marginTop = '50px'
          popup.style.overflowY = 'auto'
        }

        const confirmButton = document.querySelector('.swal-btn-green')
        if (confirmButton) {
          confirmButton.style.backgroundColor = '#28a745'
          confirmButton.style.borderColor = '#28a745'
          confirmButton.style.color = '#fff'
        }
      },
    })
  },
  methods: {
    autenticar() {
      this.loginError = ''
      this.$refs.validacao.validate()
        .then(() => {
          this.form.login = this.form.login.trim()
          useJwt.login(this.form)
            .then(payload => {
              // if (payload.data.alterarSenha) {
              //   this.$router.push({ name: 'alterar-senha-interno' })
              // } else {

              useJwt.setUserData(payload.data.userData)
              useJwt.setToken(payload.data.accessToken)
              useJwt.setPermissoesPlano(payload.data.permissaoPlano)
              updateAbility(this.$ability)

              // inicia conexão com hub após login
              eventHub()

              this.$router.push({ name: 'dashboard-analitico', params: { primeiroAcesso: true } })
            })
            .catch(error => {
              this.loginError = error.response?.data ? error.response.data.message : 'Erro ao tentar acessar o sistema. Contate o administrador!'
              this.$refs.validacao.setErrors({ Password: this.loginError })
              throw error
            })
        })
    },
    forgotPassword() {
      this.form.login = this.form.login.trim()
      useJwt.esqueciSenha(this.form.login)
        .then(() => {
          this.esqueci = {
            message: `E-mail enviado com sucesso para ${this.form.login}`,
            error: false,
          }
        })
        .catch(err => {
          const msg = (!err.message || !err.response.data)
            ? 'Não foi possível enviar o email de recuperação de senha. Contate o administrador'
            : err.response.data.message

          this.esqueci = {
            message: msg,
            error: true,
          }
        })
    },
    esqueceuSenha() {
      useJwt.forgotPassword(this.form)
        .then(payload => {
          if (payload.data === true) {
            Vue.swal({
              title: 'Redefinir senha',
              text: 'Encaminhamos um e-mail para redefinir a sua senha!',
              icon: 'warning',
              showCancelButton: false,
              confirmButtonText: 'Ok',
              customClass: {
                confirmButton: 'btn btn-outline-primary mr-50',
              },
              buttonsStyling: false,
            })
          }
        })
        .catch(error => {
          this.loginError = error.response?.data ? error.response.data.message : 'Erro ao tentar acessar o sistema. Contate o administrador!'
          this.$refs.validacao.setErrors({ email: this.loginError })
          Vue.swal({
            title: 'Ocorrem um problema',
            text: error.response.data.message,
            icon: 'warning',
            iconColor: 'sucesso',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            customClass: {
              confirmButton: 'btn btn-outline-primary mr-50',
            },
            buttonsStyling: false,
          })
        })
    },
  },
}
</script>
